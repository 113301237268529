const projectObject = [
  {
    projectName: "Touro University Website",
    label: "http://something.com",
  },
  {
    projectName: "FullBeauty Brands",
    label: "http://something.com",
  },

  {
    projectName: "Youtube Simulator (API CALL)",
    label: "http://something.com",
  },
  ,
  {
    projectName: "UX UI Design",
    label: "http://something.com",
  },
  {
    projectName: "WeatherApp (API CALL)",
    label: "http://something.com",
  },
  // {
  //   projectName: "Digital calculator",
  //   label: "http://something.com",
  // },
];

export default projectObject;
